import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { SyncLoader }  from "react-spinners";
import { colors } from "./config/designSettings";
import {
  setInstallBtn,
} from "./store/actions";

const AboutUs = lazy(() => import("./components/Menu/AboutUs/AboutUs"));
const Feedback = lazy(() => import("./components/Menu/Feedback/Feedback"));
const EditorPDF = lazy(() => import("./containers/EditorPDF/EditorPDF"));
const Menu = lazy(() => import("./containers/Menu/Menu"));
const MySongs = lazy(() => import("./containers/MySongs/MySongs"));
const Song = lazy(() => import("./containers/Song/Song"));
const Symbols = lazy(() => import("./components/Menu/Symbols/Symbols"));

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInstallBtn(false))
  })

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      window.deferredPrompt = e;
      dispatch(setInstallBtn(true))
    }
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    }
  })


  return (
    <Suspense fallback={
      <div className="suspenseSpinner">
        <SyncLoader color={colors.yellow} />
      </div>
    }>
      <Switch>
        <Route path="/new-song" component={Song} />
        <Route path="/edit-song/:songID" component={Song} />
        <Route path="/pdf-editor/:songID/:fileID" component={EditorPDF} />
        <Route path="/menu/about-us" component={AboutUs} />
        <Route path="/menu/feedback" component={Feedback} />
        <Route path="/menu" component={Menu} />
        <Route path="/symbols" component={Symbols} />
        <Route path="/" exact component={MySongs} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  )
}

export default Router;