export const sizeFactors = {
  "em": 0.9,
  "vmin": 0.5
}

export const resizeByMin = vmin => {
  return Math.round(0.9* sizeFactors.em + sizeFactors.vmin * vmin)
}


export const colors = {
  darkblue: "#261E4C",
  lightyellow: "#FFEFD9",
  yellow: "#FFB448"
};

const resize = (base) => {
  const a = sizeFactors.em; //em
  const b = sizeFactors.vmin; //vmin
  return (`calc(${base*a}em + ${base*b}vmin)`);
}

export const gradients = {
  yellow: "linear-gradient(0deg, #CB3919, #FFB448)",
  red: "linear-gradient(0deg, #8F003D, #DF0046)",
  darkblue: "linear-gradient(0deg, #181C27, #261E4C)",
  white: "linear-gradient(150deg, #ffffff14 30%, transparent 30%)",
  main:
    "linear-gradient(140deg, rgba(255, 255, 255, 0.09) 30%, transparent 30%), linear-gradient(0deg, #181C27, #261E4C)"
};

export const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: colors.lightyellow,
    border: "none",
    borderRadius: resize(0.625),
    color: colors.darkblue,
    fontFamily: "Open Sans",
    fontSize: resize(0.9375),
    padding: `${resize(0.5)} ${resize(1)}`,
    width: "100%",
    outline: "none"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    border: '2px solid orange',
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0"
  }),
  option: styles => ({
    ...styles,
    color: colors.darkblue,
    fontSize: resize(0.8125),
  }),
  menu: styles  => ({
    ...styles,
    maxHeight: resize(10),
  }),
  menuList: styles  => ({
    ...styles,
    maxHeight: resize(10),
  }),
  multiValue: styles => ({
    ...styles,
    background: colors.yellow,
    border: `${resize(0.0625)} solid ${colors.yellow}`,
    borderRadius: resize(1),
    color: colors.darkblue,
    fontFamily: "Open Sans",
    fontSize: resize(0.8125),
    fontWeight: "500",
    lineHeight: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: resize(0.0625),
    padding: `${resize(0.125)} ${resize(0.1875)} ${resize(0.125)}`,
    svg: {
      height: resize(1),
      width: resize(1),
    }
  })
};
