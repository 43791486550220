import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../src/store/store";
import { pdfjs } from "react-pdf";

import Router from "./Router";
import { fileIdbKeyval }  from "./utils/db";
import { initialPdf, initialAudio } from "./config/idb";
import "./App.scss";

// for deploying on gh-pages
// pdfjs.GlobalWorkerOptions.workerSrc = `/music_reader_react/pdf.worker.min.js`;
// for deploying on own server
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

const App = () =>  {
  fileIdbKeyval.set(initialPdf.data.id, initialPdf.src64);
  fileIdbKeyval.set(initialAudio.data.id, initialAudio.src64);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <main>
          <Router /> 
        </main>
      </PersistGate>
    </Provider>
  );
  
}

export default App;
