import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
import { reduxDb as name, store as storeName} from "../config/idb";

import rootReducer from "./reducers";

const logger = createLogger({
  diff: true,
  duration: true,
});

const persistConfig = {
  key: "root",
  storage: createIdbStorage({ name, storeName })
  // serialize: false,
  // storage,
  // blacklist: ["settings"]
};

const middlewares = [ thunk ];
if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(...middlewares));
let persistor = persistStore(store);

export { store, persistor };
