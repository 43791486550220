import {
  CLEAR_DATA,
  INCREMENT_APP_OPEN_COUNTER,
  IS_USER_FROM_EU,
  RESET_APP_OPEN_COUNTER,
  TURN_OFF_RATEUS_MODAL,
  TURN_OFF_LANGUAGE_INFO_MODAL,
  SET_LANGUAGE,
  SET_INSTALL_BTN,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  isUserFromEU: false,
  appOpenCounter: 0,
  showRateUsModal: true,
  showLanguageInfoModal: true,
  language: null,
  showInstallBtn: false,
};

const settings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return INITIAL_STATE;
    case IS_USER_FROM_EU:
      return {
        ...state,
        isUserFromEU: action.isEU
      };
    case INCREMENT_APP_OPEN_COUNTER:
      return {
        ...state,
        appOpenCounter: state.appOpenCounter + 1
      };
    case RESET_APP_OPEN_COUNTER:
      return {
        ...state,
        appOpenCounter: 0
      };
    case TURN_OFF_RATEUS_MODAL:
      return {
        ...state,
        showRateUsModal: false
      };
    case TURN_OFF_LANGUAGE_INFO_MODAL:
      return {
        ...state,
        showLanguageInfoModal: false
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.lang
      };
    case SET_INSTALL_BTN:
      return {
        ...state,
        showInstallBtn: action.value,
      };
    default:
      return state;
  }
};

export default settings;
