import {
  CREATE_NOTES_ARRAY,
  ADD_NOTE_TO_FILE,
  REMOVE_NOTE_FROM_FILE,
  REMOVE_FILE_FROM_NOTES,
  IMPORT_NOTES,
  REMOVE_NOTES_ARRAY
} from "./actionTypes";

import {
  fileIdbKeyval,
} from "../../utils/db";

export const createNotesArray = (songID, fileID) => {
  return {
    type: CREATE_NOTES_ARRAY,
    songID,
    fileID
  };
};

export const addNoteToFile = (songID, fileID, note) => {
  return {
    type: ADD_NOTE_TO_FILE,
    songID,
    fileID,
    note
  };
};

export const removeNoteFromFile = (songID, fileID, sketch) => {
  fileIdbKeyval.delete(sketch.note);
  return {
    type: REMOVE_NOTE_FROM_FILE,
    songID,
    fileID,
    page: sketch.page
  };
};

export const removeFileFromNotes = (songID, fileID) => {
  fileIdbKeyval.delete(fileID);
  return {
    type: REMOVE_FILE_FROM_NOTES,
    songID,
    fileID
  };
};

export const importNotes = (notes, id) => {
  return {
    type: IMPORT_NOTES,
    notes,
    id
  };
};

export const removeNotesArray = id => {
  return {
    type: REMOVE_NOTES_ARRAY,
    id
  };
};
