import { openDB } from 'idb';
import { 
  filesDb,
  reduxDb,
  store,
} from "../config/idb";

const dbPromise = (dbName) => openDB(dbName, 1, {
  upgrade(db) {
    db.createObjectStore(store);
  },
});

const fileDbPromise = dbPromise(filesDb);
const reduxDbPromise = dbPromise(reduxDb);

const idbKeyval = (promise) => ({
  async get(key) {
    return (await promise).get(store, key);
  },
  async set(key, val) {
    return (await promise).put(store, val, key);
  },
  async delete(key) {
    return (await promise).delete(store, key);
  },
  async clear() {
    return (await promise).clear(store);
  },
  async keys() {
    return (await promise).getAllKeys(store);
  },
});

export const fileIdbKeyval = idbKeyval(fileDbPromise);
export const reduxIdbKeyval = idbKeyval(reduxDbPromise);