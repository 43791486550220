import { combineReducers } from "redux";
import myNotes from "./myNotes";
import mySongs from "./mySongs";
import mySymbolSet from "./mySymbolSet";
import settings from "./settings";

export default combineReducers({
  mySongs,
  myNotes,
  mySymbolSet,
  settings
});
