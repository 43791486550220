import {
  ADD_SYMBOL_TO_SET,
  CLEAR_DATA,
  REMOVE_SYMBOL_FROM_SET
} from "../actions/actionTypes";

const INITIAL_STATE = [ 101, 602, 604, 605, 607 ];

const mySymbolSet = (symbolSet = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return INITIAL_STATE;
    case ADD_SYMBOL_TO_SET:
      const { symbol } = action;
      if (symbolSet.some(x => x===symbol)) return symbolSet 
      else {
        return [
          ...symbolSet,
          symbol,
        ];
      }
    case REMOVE_SYMBOL_FROM_SET:
      const { id } = action;
      const newSymbolSet = symbolSet.filter(symbol => symbol !== id)
      return [
        ...newSymbolSet,
      ];
    default:
      return symbolSet;
  }
};

export default mySymbolSet;
