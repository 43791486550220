import {
  //   INCREMENT_APP_OPEN_COUNTER,
  //   IS_USER_FROM_EU,
  //   RESET_APP_OPEN_COUNTER,
  //   TURN_OFF_RATEUS_MODAL,
  //   TURN_OFF_LANGUAGE_INFO_MODAL,
  SET_LANGUAGE,
  SET_INSTALL_BTN,
} from "./actionTypes";

export const setLanguage = lang => {
  return {
    type: SET_LANGUAGE,
    lang
  };
};

export const setInstallBtn = (value) => {
  return {
    type: SET_INSTALL_BTN,
    value
  };
};

