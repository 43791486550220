import {
  ADD_NOTE_TO_FILE,
  CLEAR_DATA,
  CREATE_NOTES_ARRAY,
  IMPORT_NOTES,
  REMOVE_FILE_FROM_NOTES,
  REMOVE_NOTE_FROM_FILE,
  REMOVE_NOTES_ARRAY,
} from "../actions/actionTypes";

import {
  fileIdbKeyval,
} from "../../utils/db";

import { keys } from 'lodash';

const deleteFiles = (idsArray) => {
  idsArray.forEach(id => fileIdbKeyval.delete(id))
}

const INITIAL_STATE = {
  "netigen_demo_01": {
    "netigen_pdf_01":[]
  }
};

const myNotes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return INITIAL_STATE;
    case CREATE_NOTES_ARRAY:
      const isFileExisting =
        state[action.songID] !== undefined &&
        state[action.songID][action.fileID] !== undefined;
      return {
        ...state,
        [action.songID]: {
          ...state[action.songID],
          [action.fileID]: isFileExisting
            ? [...state[action.songID][action.fileID]]
            : []
        }
      };
    case ADD_NOTE_TO_FILE:
      const isPageWithNotesExists = state[action.songID][action.fileID].some(
        item => item.page === action.note.page
      );
      let newState = {
        ...state,
        [action.songID]: {
          ...state[action.songID],
          [action.fileID]: isPageWithNotesExists
            ? state[action.songID][action.fileID].map(item => {
                if (item.page === action.note.page) {
                  item = action.note;
                }
                return item;
              })
            : [...state[action.songID][action.fileID], action.note]
        }
      };
      return newState;
    case REMOVE_NOTE_FROM_FILE:
      const stateWithoutNote = {
        ...state,
        [action.songID]: {
          ...state[action.songID],
          [action.fileID]: state[action.songID][action.fileID].filter(file => file.page !== action.page)
        }
      };
      return stateWithoutNote;
    case REMOVE_FILE_FROM_NOTES:
      const stateWithoutFile = { ...state };
      delete stateWithoutFile[action.songID][action.fileID];
      return stateWithoutFile;
    case IMPORT_NOTES:
      return {
        ...state,
        [action.id]: action.notes
      };
    case REMOVE_NOTES_ARRAY:
      let filesToDelete = [];
      const notes = state[action.id];
      keys(notes).forEach(id => notes[id].forEach(page => filesToDelete.push(page.note)));
      deleteFiles(filesToDelete);

      const stateWithoutNotesArray = { ...state };
      delete stateWithoutNotesArray[action.id];
      return stateWithoutNotesArray;
    default:
      return state;
  }
};

export default myNotes;
