export const filesDb = 'files-db';
export const reduxDb = 'redux-db';
export const store = 'keyval';
export const initialPdf = {
  data: {
    id: "netigen_pdf_01",
    name: "Ave Maria.pdf",
    size: 45164,
    type: "application/pdf",
  },
  src64: require("../assets/demo_projects/Ave_Maria_-_Bach-Gounod_NETIGEN.pdf"),
};
export const initialAudio = {
  data: {
    id: "netigen_mp3_01",
    name: "Ave Maria.mp3",
    size: 2423327,
    type: "audio/mpeg",
  },
  src64: require("../assets/demo_projects/Ave_Maria_-_Bach-Gounod_NETIGEN.mp3"),
};