import {
  ADD_SYMBOL_TO_SET,
  REMOVE_SYMBOL_FROM_SET
} from "./actionTypes";

export const addSymbolToSet = symbol => {
  return {
    type: ADD_SYMBOL_TO_SET,
    symbol
  };
};

export const removeSymbolFromSet = id => {
  return {
    type: REMOVE_SYMBOL_FROM_SET,
    id
  };
};
