import { CLEAR_DATA, SAVE_SONG, EDIT_SONG, REMOVE_SONG } from "../actions/actionTypes";

import { initialPdf, initialAudio } from "../../config/idb"

const INITIAL_STATE = [
  {
    id: "netigen_demo_01",
    title: "Ave Maria",
    composer: "J. S. Bach, Ch. Gounod",
    rating: 5,
    instruments: ["Piano", "Violin"],
    style: ["Classical", "Religious"],
    timePeriod: ["Baroque"],
    genre: ["instrumental"],
    noteType: ["Sheet music"],
    keys: ["C"],
    tempo: ["Andante"],
    bpm: 66,
    duration: "00:02:31",
    collections: "",
    difficulty: ["easy"],
    description: "Our free sheet music for you! Enjoy!",
    publisher: "NETIGEN",
    files: [
      initialPdf.data
    ],
    filesAudio: [
      initialAudio.data
    ],
    filesMIDI: [],
    extraInfo: {
      instruments: true,
      style: false,
      timePeriod: false,
      genre: false,
      noteType: false,
      keys: true,
      tempo: false,
      duration: false,
      collections: false,
      difficulty: false,
      publisher: true,
      description: false,
      rating: true
    }
  }
];

const mySongs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return INITIAL_STATE;
    case SAVE_SONG:
      return [...state, action.song];
    case EDIT_SONG:
      const editedState = state.map(item => {
        if (item.id === action.song.id) {
          item = { ...item, ...action.song };
        }
        return item;
      });
      return editedState;
    case REMOVE_SONG:
      const filteredState = state.filter(item => item.id !== action.id);
      return filteredState;
    default:
      return state;
  }
};

export default mySongs;
