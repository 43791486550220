export const EDIT_SONG = "EDIT_SONG";
export const REMOVE_SONG = "REMOVE_SONG";
export const SAVE_SONG = "SAVE_SONG";

export const ADD_NOTE_TO_FILE = "ADD_NOTE_TO_FILE";
export const CREATE_NOTES_ARRAY = "CREATE_NOTES_ARRAY";
export const IMPORT_NOTES = "IMPORT_NOTES";
export const REMOVE_FILE_FROM_NOTES = "REMOVE_FILE_FROM_NOTES";
export const REMOVE_NOTE_FROM_FILE = "REMOVE_NOTE_FROM_FILE";
export const REMOVE_NOTES_ARRAY = "REMOVE_NOTES_ARRAY";

export const INCREMENT_APP_OPEN_COUNTER = "INCREMENT_APP_OPEN_COUNTER";
export const IS_USER_FROM_EU = "IS_USER_FROM_EU";
export const RESET_APP_OPEN_COUNTER = "RESET_APP_OPEN_COUNTER";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_INSTALL_BTN = "SET_INSTALL_BTN";
export const TURN_OFF_RATEUS_MODAL = "TURN_OFF_RATEUS_MODAL";
export const TURN_OFF_LANGUAGE_INFO_MODAL = "TURN_OFF_LANGUAGE_INFO_MODAL";

export const ADD_SYMBOL_TO_SET = "ADD_SYMBOL_TO_SET";
export const REMOVE_SYMBOL_FROM_SET = "REMOVE_SYMBOL_FROM_SET";

export const CLEAR_DATA = "CLEAR_DATA";
