import { SAVE_SONG, EDIT_SONG, REMOVE_SONG } from "./actionTypes";

import {
  fileIdbKeyval,
} from "../../utils/db";

export const saveSong = song => {
  return {
    type: SAVE_SONG,
    song
  };
};

export const editSong = song => {
  return {
    type: EDIT_SONG,
    song
  };
};

export const removeSong = song => {
  const { files, filesAudio , filesMIDI } = song;
  const allFiles = [ ...files, ...filesAudio, ...filesMIDI ];

  allFiles.forEach(file => fileIdbKeyval.delete(file.id))
  return {
    type: REMOVE_SONG,
    id: song.id
  };
};
