import {
  CLEAR_DATA,
} from "./actionTypes";

import {
  fileIdbKeyval,
  reduxIdbKeyval,
} from "../../utils/db";

import { initialPdf, initialAudio } from "../../config/idb";

export { saveSong, editSong, removeSong } from "./mySongsActions";

export {
  createNotesArray,
  addNoteToFile,
  removeNoteFromFile,
  removeFileFromNotes,
  importNotes,
  removeNotesArray
} from "./myNotesActions";

export {
  addSymbolToSet,
  removeSymbolFromSet
} from "./mySymbolSetActions"

export {
  setInstallBtn,
} from "./settingsActions";

export const clearData = () => {
  fileIdbKeyval.clear();
  reduxIdbKeyval.clear();

  fileIdbKeyval.set(initialPdf.data.id, initialPdf.src64);
  fileIdbKeyval.set(initialAudio.data.id, initialAudio.src64);
  return {
    type: CLEAR_DATA
  }
}